<template>
  <div ref="container">
    <HeadView :noMenu="true"></HeadView>
    <div class="exam contW" v-if="!resultModal && !answerCardLoading">
      <div class="exam-tit">
        <p class="exam-tit-name"><span>考生姓名：{{ userName }}</span><span style="padding-left: 30px" v-if="userInfo?.examine_card_number">准考证号：{{userInfo?.examine_card_number}}</span></p>
        <p class="exam-tit-time">{{ surplusTime.hours ? (surplusTime.hours < 10 ? '0' + surplusTime.hours :
          surplusTime.hours) : '00' }}:{{ surplusTime.minutes ? (surplusTime.minutes < 10 ? '0' + surplusTime.minutes :
    surplusTime.minutes) : '00' }}:{{ surplusTime.seconds ? (surplusTime.seconds < 10 ? '0' + surplusTime.seconds
    : surplusTime.seconds) : '00' }}</p>
      </div>
      <div class="exam-info">
        <div class="exam-info-l">
          <div><span>考试名称：</span>{{ trainInfo.name }}</div>
          <div><span>试卷总分：</span>{{ trainInfo.total_score }}分</div>
          <div><span>及格分数：</span>{{ trainInfo.pass_score }}分</div>
          <div><span>考试时间：</span>{{ trainInfo.timelong }}分钟</div>
        </div>
        <div class="exam-info-btn" @click="submitPapers">交卷</div>
      </div>
      <div class="exam-cont">
        <div class="exam-cont-l" v-if="dataInfo.id">
          <div class="data-index">
            <p class="blue-tag">{{ typesObj[dataInfo.datasetData.type] }}</p>
            <p class="num">{{ curIndex + 1 }}/{{ answerCardList[curTypeIndex].list.length }}</p>
            <p>（ 每题 {{ answerCardList[curTypeIndex].score }} 分，共 {{ answerCardList[curTypeIndex].score *
              answerCardList[curTypeIndex].list.length }} 分）</p>
          </div>
          <div class="data-info">
            <Richtxt :dataObj="{ val: dataInfo.datasetData.name }" :isInline="true" :isDisabled="true"></Richtxt>
            <div class="data-info-options">
              <div class="cont info-options"
                v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 2 || dataInfo.datasetData.type == 3">
                <div v-for="item in dataInfo.datasetDataOptions" :key="item.id" :class="item.select ? 'active' : ''"
                  @click="selectAnswer(item)">
                  <template v-if="dataInfo.datasetData.type == 1 || dataInfo.datasetData.type == 3">
                    <img v-if="item.select" src="../../assets/images/new_icon/xzdanxuan.png" width="19" height="19" />
                    <img v-else src="../../assets/images/new_icon/wxdanxuan.png" width="19" height="19" />
                  </template>
                  <template v-else-if="dataInfo.datasetData.type == 2">
                    <img v-if="item.select" src="../../assets/images/new_icon/select_checkbox.png" width="16"
                      height="16" />
                    <img v-else src="../../assets/images/new_icon/checkbox.png" width="16" height="16" />
                  </template>
                  <Richtxt :dataObj="{ val: item.name }" :isInline="true" :isDisabled="true"></Richtxt>
                </div>
              </div>

              <div class="cont mt10" v-else-if="dataInfo.datasetData.type == 4">
                <Input class="cont-input mb10" size="large" v-for="(item, index) in dataInfo.datasetDataOptions"
                  :key="item.id" v-model="item.myAnswer">
                <span slot="prepend">{{ index + 1 }}</span>
                </Input>
              </div>
              <div class="cont" v-else-if="dataInfo.datasetData.type == 9">
                <el-upload :action="constant.URL + '/uploadfile/upload'" :data="{
                  type: 3
                }" :headers="{
  'access-token': Cookies.get(constant.tokenName)
}" :on-success="uploadFileSuccess" :show-file-list="false">
                  <div class="upload-btn">
                    <Button type="primary" @click="beforeUpload">上传文件</Button>
                  </div>
                </el-upload>
                <!--                <Button type="primary" v-else @click="downLoad(uploadPath)">下载文件</Button>-->
                <p class="mt10" v-if="uploadName">文件地址：{{ uploadPath }}</p>
              </div>
              <div class="cont" v-else>
                <Richtxt placeholder="请输入内容" ref="richtxt" style="width: 100%" :height="150"
                  :dataObj="{ val: shortAnswer }" @saveData="saveAnswer" @textareaFocus="changeTextareaFocus"></Richtxt>
              </div>
            </div>
          </div>
          <div class="data-btn">
            <div :class="curTypeIndex == 0 && curIndex == 0 ? 'gray' : ''" @click="pre">上一题</div>
            <div
              :class="curTypeIndex >= answerCardList.length - 1 && curIndex >= answerCardList[curTypeIndex].list.length - 1 ? 'gray' : ''"
              @click="next">下一题</div>
            <div class="active" @click="submitAnswer">提交</div>
          </div>
        </div>
        <div class="exam-cont-r">
          <div class="video-wrap" v-if="this.trainInfo.is_open_monitor === '1'" ref="videoRef">
            <video autoplay playsinline ref="localVideo"></video>
            <video autoplay playsinline ref="remoteVideo" style="display: none;"></video>
          </div>
          <div v-for="(item, index) in answerCardList" :key="index">
            <p class="exam-cont-r-tit">{{ item.name }}（{{ item.list.length }}题）</p>
            <div class="exam-cont-r-list">
              <p v-for="(sItem, sIndex) in item.list" :key="sIndex"
                :class="{ green: sItem.userData && sItem.userData.answer, blue: curIndex == sIndex && curTypeIndex == index }"
                @click="selectCardData(sIndex, index)">{{ sIndex + 1 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mask" v-if="changeScreenStatus && !finish">
        <p>考试过程中不能切屏</p>
        <p>切屏次数超过5次自动交卷</p>
      </div>
      <el-dialog :visible.sync="confirmSubmitModal" width="310px" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false">
        <div slot="title"></div>

        <div class="confirm-submit-txt">{{ hasUnanswer ? '你还有未答题，确定要提交吗？' : '确定提交试卷吗？' }}</div>
        <div slot="footer" class="confirm-submit-txt-btn">
          <template v-if="hasUnanswer">
            <div @click="confirmSubmit">确定提交</div>
            <div class="active" @click="confirmSubmitModal = false">返回答题</div>
          </template>
          <template v-else>
            <div @click="confirmSubmitModal = false">返回检查</div>
            <div class="active" @click="confirmSubmit">确定提交</div>
          </template>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="confirmExamDialog" width="310px" :close-on-click-modal="false"
        :close-on-press-escape="false" :show-close="false">
        <div slot="title"></div>

        <div class="confirm-submit-txt">确定开始考试吗？</div>
        <!--        <div class="confirm-submit-txt-sub">开始考试后将扣除1次考试次数</div>-->
        <div slot="footer" class="confirm-submit-txt-btn">
          <template>
            <div @click="cancelExam">先不考了</div>
            <div class="active" @click="startExam">开始考试</div>
          </template>
        </div>
      </el-dialog>
      <!--      <el-dialog-->
      <!--          :visible.sync="resultModal"-->
      <!--          width="310px"-->
      <!--          :close-on-click-modal="false"-->
      <!--          :close-on-press-escape="false"-->
      <!--          :show-close="false"-->
      <!--      >-->
      <!--        <div slot="title"></div>-->
      <!--        <div class="result-modal-nr">-->
      <!--          <div class="result-modal-nr-t">-->
      <!--            <span class="result-modal-nr-t-num">{{ trainUser.score }}</span>-->
      <!--            <span>分</span>-->
      <!--            <span class="result-modal-nr-t-tag " :class="trainUser.is_pass == 1 ? 'green' : 'red'">{{trainUser.is_pass == 1 ? '已通过' : '未通过'}}</span>-->
      <!--          </div>-->
      <!--          <div class="result-modal-nr-c" v-if="trainUser.is_pass == 1">恭喜你！{{ userInfo.realname }}，考试已通过 !</div>-->
      <!--          <div class="result-modal-nr-c" v-else>很遗憾！{{ userInfo.realname }}，此次测试未通过！</div>-->
      <!--          <div class="result-modal-nr-desc" v-if="trainUser.is_pass == 1">证书生成中，请耐心等待5-30分钟</div>-->
      <!--          <div class="result-modal-nr-desc" v-else>还需继续努力，争取下次取得好成绩！</div>-->
      <!--        </div>-->
      <!--        <div slot="footer" class="result-modal-btn">-->
      <!--          <template v-if="trainUser.is_pass == 1">-->
      <!--            <div class="active" @click="goDetail">查看详情</div>-->
      <!--          </template>-->
      <!--          <template v-else>-->
      <!--            <div @click="goDetail">查看详情</div>-->
      <!--            <div class="active" @click="examAgain">重新考试</div>-->
      <!--          </template>-->
      <!--        </div>-->
      <!--      </el-dialog>-->
    </div>
    <StuExamFinish v-if="resultModal && !answerCardLoading" :trainId="trainId" :userInfo="userInfo" :isSelf="isSelf">
    </StuExamFinish>



  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Richtxt from '../../components/production/richtxt.vue';
import Watermark from "@/utils/waterMark";
import HeadView from '@/views/layout/components/head.vue'
import Cookies from "js-cookie";
import StuExamFinish from './stuExamFinish'
import "aliyun-webrtc-sdk";
import sha256 from "@/assets/sha256/sha256.js";
import { MessageBox } from "element-ui";
import html2canvas from 'html2canvas';
export default {
  name: "stuExamView",
  data() {
    return {
      Cookies: Cookies,
      categoryId: '',
      answerCardLoading: true,
      trainInfo: {},
      answerCardList: [],
      typesObj: {},
      curIndex: 0,
      time: '',
      surplusTime: '',
      trainUserId: '',
      trainId: '',
      infoLoading: false,
      curTypeIndex: 0,
      dataInfo: {},
      confirmSubmitModal: false,
      hasUnanswer: false,
      resultModal: false,
      finish: false,
      changeScreen: 0,
      changeScreenStatus: false,
      changeScreenTimer: null,
      trainUser: {},
      userInfo: {},
      userName: '',
      confirmExamDialog: false,
      start: false,
      timer: null,
      isSelf: false,
      isMatch: false,
      uploadName: '',
      shortAnswer: '',
      textareaFocus: false,
      uploadFocus: false,
      commitLoading: false,
      examineId: '',


      aliWebrtc: null,
      liveRoomInfo: {
        // 房间id(阿里用)
        roomId: "",
        recordId: "",
        hostId:"",
        // 房间id（平台用）
        liveDetailID:""
      },
      move: false,

      // 摄像头截屏相关
      canvas: null,
      ctx: null,
      camreaTimer: null,
      screenTimer:null,
      screenSharingStatus:0,
      socket:null,
      closeSocket:false
    }
  },
  components: {
    Richtxt,
    HeadView,
    StuExamFinish
  },
  // beforeRouteLeave(to, from, next) {
  //   if(this.finish){
  //     next();
  //   }else{
  //     this.$confirm("离开页面,考试自动结束,请确认是否离开", "提示", {
  //       confirmButtonText: "确定",
  //       cancelButtonText: "取消",
  //       type: "warning",
  //     })
  //         .then(() => {
  //           this.confirmSubmit();//交卷
  //           next();
  //         })
  //         .catch(() => {
  //           // alert("router")
  //         });
  //   }
  // },
  watch: {
    changeScreen(newV) {
      if (newV > 5) {
        this.commitAnswer();
        this.confirmSubmit();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.changeScreenTimer)
    clearInterval(this.timer)
    document.removeEventListener("contextmenu", this.disableRight);
    window.removeEventListener("focus", this.pageHidden);

    // 如果开启监控
    if (this.trainInfo.is_open_monitor === "1") {
      this.monitorHandle();
      document.removeEventListener("paste", this.monitoringPasting);
      this.closeSocket=true;
      this.socket.close();
    }
    // 清除定时器
    clearTimeout(this.camreaTimer);
    clearTimeout(this.screenTimer);
  },
  created() {
    this.trainId = this.$route.query.id || '';
    this.isSelf = this.$route.query.isSelf == 'true' || false;
    this.isMatch = this.$route.query.isMatch || false;
    this.examineId = this.$route.query.examineId || '';
    this.getAnswerCard();
    window.addEventListener("focus", this.pageHidden);
    this.getUserInfo();
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo');
    this.userInfo = userInfo ? JSON.parse(userInfo).user : {};
    document.addEventListener("contextmenu", this.disableRight);
  },
  methods: {
    // /** 在刷新和关闭之前询问 **/
    // beforeRefreshClose() {
    //   let self = this;
    //   window.onbeforeunload = function (e) {
    //     if (self.$route.name == "attestationExam") {
    //       e = e || window.event;
    //       // 兼容IE8和Firefox 4之前的版本
    //       if (e) e.returnValue = "关闭提示1";
    //       // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //       return "关闭提示2";
    //     } else window.onbeforeunload = null;
    //   };
    // },
    changeTextareaFocus() {
      this.textareaFocus = true;
    },
    beforeUpload() {
      this.uploadFocus = true;
      // console.log(this.uploadFocus,'this.uploadFocus')
    },
    getUserInfo() {
      let params = {
        user_id: localStorage.getItem('userId')
      };
      this.api.user.userDetail(params).then((res) => {
        localStorage.setItem('userInfo', JSON.stringify(res));
        this.userInfo = res.user;
        this.userName = res.user.realname;

        this.$store.state.app.settings = {
          ...res.settings
        };
        if (this.userInfo) {
          this.$store.state.user.userInfo = {
            ...this.$store.state.user.userInfo,
            ...this.userInfo
          };
        }

      })
    },
    disableRight(e) {
      e.preventDefault();
    },
    pageHidden(e = null, isReduce = 0, router = false) {
      if (this.start && !this.textareaFocus && !this.uploadFocus && this.screenSharingStatus>0) {
        this.changeScreen++;
        this.changeScreenStatus = true;
        clearTimeout(this.changeScreenTimer)
        this.changeScreenTimer = setTimeout(() => {
          this.changeScreenStatus = false;
        }, 3000);
        // 防切屏

        if(this?.trainInfo?.is_open_limit_cut==="1"){
          this.$nextTick(() => {
            this.screenShot("1");
          });
        }else{
          console.log("未开启防切屏");
        }
      }
      if (this.textareaFocus) {
        this.textareaFocus = false;
      }
      if (this.uploadFocus) {
        this.uploadFocus = false;
      }
      this.screenSharingStatus++;
    },
    getAnswerCard(index) {
      let params = {
        action: 'testList',
        train_id: this.trainId,
      };

      if (this.siteId) {
        params.site_id = this.siteId
      }
      if (this.userId) {
        params.user_id = this.userId
      }
      if (this.trainUserId) {
        params.train_user_id = this.trainUserId;
      }

      if (this.examineId) {
        params.data_rule = 12;
        params.data_type = 13;
        params.generate_id = this.examineId;
        params.from_type = 6;
        params.from_id = this.examineId;
        params.type = 1;
      }

      if (this.from == 'analysis') {
        params.site_id = '-1';
        params.user_id = '-1';
      }

      this.answerCardLoading = true;
      this.api.dataset.trainExecute(params).then((res) => {
        console.warn("题的信息", res);
        this.answerCardLoading = false;
        this.trainInfo = res.train;
        // 是否需要监控
        if (this.trainInfo.is_open_monitor === '1') {
          this.liveRoomInfo.liveDetailID = res.live_room.id;
          this.liveRoomInfo.roomId=res?.live_room?.room_key || res.live_room.id;
          this.initWebRTC();
          this.initWebSocket();
        }
        if (res.data_list.length) {
          this.answerCardList = [];
          for (let name in res.data_list_by_type) {
            let obj = {
              name: res.types[name],
              id: name,
              list: res.data_list_by_type[name]
            }
            obj.score = res.train_scores[name];
            this.answerCardList.push(obj);
          }
          this.typesObj = res.types;
          this.getInfo(); //获取数据详情
        }
        this.trainUserId = res.train_user.id;
        this.trainId = res.train.id;
        // if(!res.train_user.start_time || res.train_user.start_time == '0'){
        //   this.confirmExamDialog = true;
        // }else{
        //   if(res.train_user.status < 2){
        //     this.startExam();
        //   }
        //
        // }
        this.getCountdownTime();
        this.start = true;
        if (res.train_user.status >= 2) {
          this.resultModal = true;
        } else {
          this.resultModal = false;
        }
        this.$nextTick(() => {
          Watermark.set(`${this.userInfo.nickname}(id:${this.userInfo.id})`, this.$refs.container);
        });
      }).catch((err) => {
        this.answerCardLoading = false;
        this.$router.go(-1);
      })
    },
    startExam() {
      let params = {
        action: 'trainUserStart',
        train_user_id: this.trainUserId,
      };

      this.api.dataset.trainExecute(params).then((res) => {
        this.getCountdownTime();
        this.confirmExamDialog = false;
        this.start = true;
      })

    },
    cancelExam() {
      this.$router.go(-1);
    },
    //获取时间 开始倒计时
    getCountdownTime() {
      let params = {
        action: 'trainUserInfo',
        train_id: this.trainId
      };
      this.api.dataset.trainExecute(params).then((res) => {
        this.time = res.time_countdown;
        if (this.time != '0' && res.train_user.start_time != '0') {
          this.surplusTime = util.timeFormate(this.time);
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.time <= 0) {
              // this.submitPapers();
              this.confirmSubmit();
              clearInterval(this.timer);
              return;
            }
            this.time--;
            this.surplusTime = util.timeFormate(this.time);
          }, 1000)
        }
      })
    },
    getInfo() {  //获取详情数据
      let params = {
        action: 'testListInfo',
        group_id: this.answerCardList[this.curTypeIndex].list[this.curIndex].train_id,
        data_id: this.answerCardList[this.curTypeIndex].list[this.curIndex].data_id,
        train_user_id: this.trainUserId,
        train_id: this.trainId,
      };
      this.infoLoading = true;
      this.api.dataset.trainExecute(params).then((res) => {

        this.dataInfo = res[0];
        if (this.dataInfo.datasetData.type == 1 || this.dataInfo.datasetData.type == 2 || this.dataInfo.datasetData.type == 3) {
          if (this.answerCardList[this.curTypeIndex].list[this.curIndex].userData) {
            let curAnswer = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split(',');

            this.dataInfo.datasetDataOptions.forEach((item) => {
              let len = curAnswer.filter((sItem) => {
                return item.id == sItem;
              }).length;
              if (len) {
                this.$set(item, 'select', true);
              }
            })
          }
        } else if (this.dataInfo.datasetData.type == '9') {  //实操
          if (this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer) {
            let urlArr = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split('/');
            this.uploadName = urlArr[urlArr.length - 1];
            this.uploadPath = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer;
          } else {
            this.uploadName = '';
            this.uploadPath = '';
          }
        } else if (this.dataInfo.datasetData.type == '4') {  //填空
          let answer;
          if (this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer) {
            answer = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split(',');
          }
          this.dataInfo.datasetDataOptions.forEach((item, index) => {
            if (answer[index]) {
              this.$set(item, 'myAnswer', answer[index]);
            } else {
              this.$set(item, 'myAnswer', '');
            }
          })
        } else {  //8：简答、10：实验
          this.shortAnswer = this.answerCardList[this.curTypeIndex].list[this.curIndex]?.userData?.answer || '';
        }



        this.startTime = this.getTime();
        this.infoLoading = false;
      }).catch((err) => {
        this.infoLoading = false;
      })
    },
    selectCardData(sIndex, index) {
      this.commitAnswer(() => {
        this.curIndex = sIndex;
        this.curTypeIndex = index;
        // this.curData = this.answerCardList[this.curTypeIndex].list[this.curIndex];
        this.getInfo();
      });
    },
    selectAnswer(data) { //选择答案
      if (this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3') {  //1单选 3判断
        this.dataInfo.datasetDataOptions.forEach((item) => {
          this.$set(item, 'select', false);
        })
        this.$set(data, 'select', true);
      }
      if (this.dataInfo.datasetData.type == '2') {  //2 多选
        this.$set(data, 'select', !data.select);
        // let answers = this.dataInfo.datasetDataOptions.filter((item)=>{
        //   return item.select;
        // }).map((item)=>{
        //   return item.id;
        // }).join(',');
      }
    },
    pre() {  //上一题
      if (this.infoLoading || this.commitLoading) return;
      // if(this.curIndex == 0 && this.curTypeIndex == 0) return;
      this.commitAnswer(() => {
        if (this.curIndex > 0) {
          this.curIndex--;
          this.getInfo();
        } else {
          if (this.curTypeIndex > 0) {
            this.curTypeIndex--;
            this.curIndex = this.answerCardList[this.curTypeIndex].list.length - 1;
            this.getInfo();
          }
        }
      });
    },
    next() { //下一题
      if (this.infoLoading || this.commitLoading) return;
      // if(this.curTypeIndex == this.answerCardList.length - 1 && this.curIndex == this.answerCardList[this.curTypeIndex].list.length - 1) return;
      this.commitAnswer(() => {
        if (this.curIndex < this.answerCardList[this.curTypeIndex].list.length - 1) {
          this.curIndex++;
          this.getInfo();
        } else {
          if (this.curTypeIndex < this.answerCardList.length - 1) {
            this.curTypeIndex++;
            this.curIndex = 0;
            this.getInfo();
          }
        }
      })

    },
    submitAnswer() {
      this.commitAnswer(() => {
        if (this.curTypeIndex == this.answerCardList.length - 1 && this.curIndex == this.answerCardList[this.curTypeIndex].list.length - 1) {
          this.$message({
            message: '提交成功，请返回检查或交卷',
            type: 'success'
          });
        }

        if (this.curIndex < this.answerCardList[this.curTypeIndex].list.length - 1) {
          this.curIndex++;
          this.getInfo();
        } else {
          if (this.curTypeIndex < this.answerCardList.length - 1) {
            this.curTypeIndex++;
            this.curIndex = 0;
            this.getInfo();
          }
        }

      }, 'submit')
    },
    getTime() {
      let oDate = new Date();
      let time = Math.floor(oDate.getTime() / 1000);

      return time
    },
    submitPapers() { //交卷
      this.commitAnswer();
      let unAnswer = [];
      this.answerCardList.forEach((item) => {
        let arr = item.list.filter((sItem) => {
          return !sItem.userData || (sItem.userData && !sItem.userData.answer)
        })
        if (arr.length) {
          unAnswer.push(...arr)
        }
      })
      if (unAnswer.length) {
        this.hasUnanswer = true;
      } else {
        this.hasUnanswer = false;
      }
      // console.log(this.answerCardList,this.hasUnanswer,'this.answerCardListthis.answerCardList')
      this.confirmSubmitModal = true;
    },
    commitAnswer(fn, type) { //提交答案

      this.endTime = this.getTime();
      let date = this.endTime - this.startTime;

      let answer_data = [
        {
          id: this.answerCardList[this.curTypeIndex].list[this.curIndex].data_id,
          use_time: date
        }
      ];
      if (this.dataInfo.datasetDataOptions && this.dataInfo.datasetDataOptions.length) {
        if (this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '2' || this.dataInfo.datasetData.type == '3') {
          let answers = this.dataInfo.datasetDataOptions.filter((item) => {
            return item.select;
          }).map((item) => {
            return item.id;
          }).join(',');
          answer_data[0].answer = answers;
        }
        if (this.dataInfo.datasetData.type == '4') {
          let len = this.dataInfo.datasetDataOptions.filter((item) => {
            return item.myAnswer;
          }).length;
          if (!len && !this.dataInfo.userData.id) {
            return;
          } else {
            let answer = this.dataInfo.datasetDataOptions.map((item) => {
              return item.myAnswer;
            })
            answer_data[0].answer = answer.join(',');
          }

        }
      } else {
        answer_data[0].answer = '';
      }

      if (this.dataInfo.datasetData.type == '9') {  //实操
        answer_data[0].answer = this.uploadPath;
      }
      if (this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10') {  //简答、实验
        answer_data[0].answer = this.shortAnswer;
      }

      if (type == 'submit') {
        if (!answer_data[0].answer) {
          this.$message({
            message: '请选择答案',
            type: 'warning'
          });
          return;
        }
      } else {
        if (!answer_data[0].answer) {
          fn && fn();
          return;
        }

      }

      let params = {
        action: 'exerciseSubmit',
        use_time: date,
        answer_data: JSON.stringify(answer_data),
        train_user_id: this.trainUserId,
        train_id: this.trainId,
      };
      this.commitLoading = true;
      this.api.dataset.trainExecute(params).then(() => {
        if (!this.answerCardList[this.curTypeIndex].list[this.curIndex].userData) {
          this.$set(this.answerCardList[this.curTypeIndex].list[this.curIndex], 'userData', {});
        }
        this.$set(this.answerCardList[this.curTypeIndex].list[this.curIndex].userData, 'answer', answer_data[0].answer);
        fn && fn();
        this.commitLoading = false;
      }).catch(() => {
        this.commitLoading = false;
      })
    },
    confirmSubmit() {  //确认交卷

      let params = {
        action: 'trainUserInfo',
        train_id: this.trainId
      };
      this.api.dataset.trainExecute(params).then((res) => {
        // console.log(res.time_countdown,'res.time_countdownres.time_countdownres.time_countdown')

        if (res.time_countdown == -1) {
          this.start = false;
          clearInterval(this.timer)
          this.$router.push({
            path: '/user/certificate'
          })
        } else {
          let submitParams = {
            action: 'trainUserFinish',
            train_user_id: this.trainUserId,
          };
          clearInterval(this.timer)
          this.api.dataset.trainExecute(submitParams).then((res) => {
            // this.formatInfoData(res.data_list[0]);

            this.confirmSubmitModal = false;
            this.finish = true;
            this.start = false;

            this.resultModal = true;
            this.trainUser = res.train_user;

            // this.$router.push({
            //   path:this.path
            // })
          })
        }
      })

    },
    goDetail() {
      this.$router.push({
        path: '/user/certificate'
      })
    },
    examAgain() {
      this.$router.push({
        path: '/attestation/train',
        name: 'attestationTrain',
        params: {
          tab: 'exam',
        },
        query: {
          id: this.categoryId
        }
      })
    },
    uploadFileSuccess(response, file, fileList) {  //上传文件成功
      this.uploadPath = response.data.info.upload_path;
      this.uploadName = response.data.info.name;
    },
    // downLoad(data){
    //   let name = data.split('/');
    //   name = name[name.length -1].split('.');
    //   name = name[0] + '.' + name[1];
    //   FileSaver.saveAs(data,name);
    // },
    saveAnswer(data) {
      this.shortAnswer = data;
    },





    // 初始化音视频
    initWebRTC() {
      /* eslint-disable-next-line */
      this.aliWebrtc = new AliRtcEngine();
      this.aliWebrtc.isSupport().then(() => {
        // 初始化音视频监听
        this.addWebRTCListening();
        // 获取直播间详情
        this.getLiveRoomDetail();
      });
      // 初始化摄像头截屏
      this.canvas = document.createElement("canvas");
      this.ctx = this.canvas.getContext("2d");
      // 初始化粘贴复制监听
      document.addEventListener('paste', this.monitoringPasting);
    },
    // 初始化websocket
    initWebSocket(){
      const token = Cookies.get(this.constant.tokenName);
      let heartbeatTimer=null;
      this.socket = new WebSocket(`${this.constant.WSS}?token=${token}`);
      this.socket.onopen = () => {
        console.log("%cwebsocket打开", "color:lightpink");
        heartbeatTimer=setInterval(()=>{
          this.socket.send(JSON.stringify({data:"0",message:"0"}));
        },5000);
      }
      this.socket.onmessage = (event) => {
        const socketData = JSON.parse(event.data);
        // 强制交卷
        if (socketData?.data?.action === "examForceSubmit") {
          this.submissionOfPapers();
        }
        // 标记作弊
        if(socketData?.data?.action==="examMarkCheat"){
          this.markCheating();
        }
        // 消息提醒
        if(socketData?.data?.action==="monitorNoticeUsers"){
          this.warningPopup(socketData.data);
        }
        // 个人语音提醒
        if(socketData?.data?.action==="liveRoomAudioUnicast"){
          this.voiceReminder(socketData.data.user_id);
        }
        // 全体语音提醒
        if(socketData?.data?.action==="liveRoomAudioBroadcast"){
          this.voiceReminder(socketData.data.user_id);
        }
      }
      this.socket.onclose=()=>{
        clearInterval(heartbeatTimer);
        if(!this.closeSocket){
          let timer=null;
          timer=setTimeout(()=>{
            console.log("websocket断线重连");
            this.initWebSocket();
            clearTimeout(timer);
          },1000);
        }
      }
    },


    // 强制交卷弹窗
    submissionOfPapers(){
      MessageBox.confirm("您已被老师强制交卷", "提示", {
        type: "warning",
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false
      }).then(() => {
        this.confirmSubmit();
      });
    },
    // 标记作弊弹窗
    markCheating(){
      MessageBox.confirm("您已被老师标记作弊", "提示", {
        type: "warning",
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false
      });
    },
    // 警告弹窗
    warningPopup(data){
      MessageBox.confirm(data.message, "老师警告", {
        type: "warning",
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false
      });
    },
    // 语音提醒
    voiceReminder(id){
      this.subscribe(id);
    },

    // 绑定监听事件
    addWebRTCListening() {
      // 远程检测到关闭推流
      this.aliWebrtc.on("onUnPublisher", (publisher) => {
        this.unSubscribe(publisher.userId);
      });
    },
    // 获取直播间详情（成功之后调用加入房间）
    getLiveRoomDetail() {
      const { liveRoomInfo } = this;
      this.api.live.getLiveRoomDetail({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(res => {
        liveRoomInfo.userId = res?.user?.id;
        liveRoomInfo.hostId = res?.info?.user_id;
        console.log("直播间详情",res);
        // 加入房间
        this.joineroom();
      });
    },


    // 加入房间（成功之后调用预览和推流）
    joineroom() {
      const { liveRoomInfo } = this;
      // 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
      const authInfo = this.generateAliRtcAuthInfo();
      // 加入房间（请求平台）
      this.api.live.enterLiveRoom({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(res => {
        liveRoomInfo.recordId = res.live_room_user.id;
        // 加入房间（请求阿里）
        this.aliWebrtc.joinChannel(authInfo, liveRoomInfo.nickname).then(() => {
          this.startStreaming();
        });
      });
    },
    // 离开房间
    leaveroom() {
      const { liveRoomInfo } = this;
      this.api.live.leaveLiveRoom({
        live_room_id: liveRoomInfo.liveDetailID,
        live_room_user_id: liveRoomInfo.recordId
      }).then(() => {
        // 销毁会自动退出房间的
        this.aliWebrtc.dispose();
      }).catch(()=>{
        // 销毁会自动退出房间的
        this.aliWebrtc.dispose();
      });
    },
    // 获取用户鉴权信息
    generateAliRtcAuthInfo() {
      const { liveRoomInfo } = this;
      const appId = "buotssyu"; // 修改为自己的appid 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
      const key = "da9e361834bc628ce87c89e171db6752";     // 修改为自己的key 该方案仅为开发测试使用，正式上线需要使用服务端的AppServer
      const userId = liveRoomInfo.userId;
      const timestamp = parseInt(new Date().getTime() / 1000 + 48 * 60 * 60);
      const nonce = 'AK-' + timestamp;
      const token = sha256(appId + key + liveRoomInfo.roomId + userId + nonce + timestamp);
      const res = {
        appid: appId,
        userid: userId,
        timestamp: timestamp,
        nonce: nonce,
        token: token,
        gslb: ["https://rgslb.rtc.aliyuncs.com"],
        channel: liveRoomInfo.roomId
      };
      return res;
    },


    // 开始预览
    startPreview() {
      this.aliWebrtc.startPreview(this.$refs.localVideo).then(() => {
        this.$refs.localVideo.onloadeddata = () => {
          // 人脸识别
          if(this?.trainInfo?.is_open_face_identify==="1"){
            this.cameraScreenshot();
          }else{
            console.log("未开启人脸识别");
          }
          // 屏幕识别
          if(this?.trainInfo?.is_open_screen_identify==="1"){
            this.screenShot();
          }else{
            console.log("未开启屏幕识别");
          }

        }
      });
    },
    // 停止预览
    stopPreview() {
      return new Promise((resolve)=>{
        this.aliWebrtc.stopPreview().then(()=>{
          resolve();
        });
      });
    },


    // 开始推流
    startStreaming() {
      const { liveRoomInfo,trainInfo } = this;
      this.aliWebrtc.configLocalCameraPublish = true;
      this.aliWebrtc.configLocalAudioPublish = true;
      if(trainInfo.is_open_screen==="1"){
        this.aliWebrtc.configLocalScreenPublish = true;
      }
      this.api.live.startStreaming({
        live_room_id: liveRoomInfo.liveDetailID
      }).then(() => {
        this.aliWebrtc.publish().then(() => {
          this.startPreview();
        });
      });
    },
    // 停止推流
    stopStreaming() {
      return new Promise((resolve)=>{
        this.aliWebrtc.unPublish().then(()=>{
          resolve();
        });
      });
    },
    // 如果开启监控，离开页面之前调用这个
    monitorHandle() {
      Promise.all([this.stopStreaming(),this.stopPreview()]).then(()=>{
        this.leaveroom();
      });
    },

    // *订阅相关代码
    // 订阅
    subscribe(userId){
      const { liveRoomInfo }=this;
      if(!userId) return;
      // 如果不是老师的流不订阅
      if(userId!==liveRoomInfo.hostId) return;
      this.aliWebrtc.configRemoteAudio(userId, true);
      this.aliWebrtc.subscribe(userId).then(() => {
        this.$nextTick(() => {
          console.log("订阅音频流");
          this.aliWebrtc.setDisplayRemoteVideo(userId, this.$refs.remoteVideo, 1);
        });
      });
    },
    // 取消订阅
    unSubscribe(userId) {
      if (!userId) return;
      this.aliWebrtc.unSubscribe(userId);
    },


    // 相机截屏
    cameraScreenshot() {
      const { canvas, ctx, $refs: { localVideo }, liveRoomInfo,trainInfo } = this;
      canvas.width = localVideo.videoWidth;
      canvas.height = localVideo.videoHeight;
      ctx.drawImage(localVideo, 0, 0, localVideo.videoWidth, localVideo.videoHeight);
      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("name", new Date().getTime() + ".png");
        formData.append("type", "0");
        this.api.index.uploadfileUpload(formData).then(res => {
          this.api.live.monitoringReport({
            live_room_id: liveRoomInfo.liveDetailID,
            uploadfile_id: res?.info?.id,
            type: "1",
            act_type: "0"
          }).then(res => {
            console.log("人脸截图上传成功", res);
          });
        });
      });
      // 循环随机的时间[25-35]调用
      const delay = 30000 + Math.floor(Math.random() * 11) - 5;
      this.camreaTimer = setTimeout(() => {
        clearTimeout(this.camreaTimer);
        this.camreaTimer = null;
        this.cameraScreenshot();
      }, delay);
    },

    // 屏幕截图
    screenShot(act_type = "0") {
      const { liveRoomInfo } = this;
      html2canvas(document.body, { scale: 0.5 }).then(canvas => {
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append("file", blob);
          formData.append("name", new Date().getTime() + ".png");
          formData.append("type", "0");
          this.api.index.uploadfileUpload(formData).then(res => {
            this.api.live.monitoringReport({
              live_room_id: liveRoomInfo.liveDetailID,
              uploadfile_id: res?.info?.id,
              type: "0",
              act_type: act_type // 1切屏记录 2复制粘贴 0正常记录
            }).then(res => {
              console.log("屏幕截图上传成功", res);
            });
          });
        });
      });

      const delay = 30000 + Math.floor(Math.random() * 11) - 5;
      this.screenTimer = setTimeout(() => {
        clearTimeout(this.screenTimer);
        this.screenTimer = null;
        this.screenShot();
      }, delay);
    },
    // 复制粘贴检测函数
    monitoringPasting() {
      if(this.trainInfo.is_open_screen_identify==="1"){
        this.screenShot("2");
      }else{
        console.log("未开启屏幕识别-复制粘贴");
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exam {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  background-color: #FFFFFF;
  margin: 20px;
  padding: 20px;
  font-size: 16px;
  height: calc(100vh - 100px);

  .exam-tit {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .exam-tit-name {
      font-size: 18px;
      font-weight: bold;
    }

    .exam-tit-time {
      font-size: 20px;
      font-weight: bold;
      color: #4A79FF;
    }
  }

  .exam-info {

    height: 73px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 35, 136, 0.08);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .exam-info-l {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >div {
        flex: 1;
        padding-left: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        >span {
          font-weight: bold;
        }
      }
    }

    .exam-info-btn {
      margin-right: 30px;
      width: 170px;
      height: 48px;
      border-radius: 24px;
      font-size: 16px;
      background: linear-gradient(222deg, #3A6CFF, #004FEB);
      font-weight: bold;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .exam-cont {
    margin-bottom: 80px;
    width: 100%;
    flex: 1;
    height: 0;
    background: #FAFBFC;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-top: 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .exam-cont-l {
      flex: 1;
      width: 0;
      height: 100%;
      overflow-y: auto;

      .data-index {
        margin: 26px 0 30px 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;

        .blue-tag {
          width: 56px;
          height: 23px;
          border-radius: 4px;
          font-size: 12px;
          background: #4A79FF;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .num {
          margin: 0 10px;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .data-info {
        margin: 0 30px;
        line-height: 24px;

        .data-info-options {
          margin: 30px 0 20px 0;
          padding-bottom: 30px;

          .info-options {
            >div {
              margin-bottom: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              cursor: pointer;

              >img {
                margin-top: 2px;
                margin-right: 10px;
              }
            }
          }

          .active {
            color: #4A79FF;
          }
        }
      }

      .data-btn {
        margin: 20px 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        >div {
          margin-right: 14px;
          width: 70px;
          height: 28px;
          border-radius: 4px;
          font-size: 14px;
          background: #E1E9FF;
          color: #1664FF;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .active {
          background: #1664FF;
          color: #FFFFFF;
        }

        .gray {
          background: #E0E0E0;
          color: #999999;
        }
      }
    }

    .exam-cont-r {
      width: 250px;
      height: 100%;
      overflow-y: auto;
      background-color: #FFFFFF;

      .exam-cont-r-tit {
        margin: 32px 0 18px 30px;
        font-size: 16px;
        font-weight: bold;
      }

      .exam-cont-r-list {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        >p {
          margin: 0 10px 10px 0;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          background: #E0E0E0;
          color: #666666;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }

  .confirm-submit-txt {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .confirm-submit-txt-sub {
    margin-top: 10px;
    text-align: center;
  }

  .confirm-submit-txt-btn,
  .result-modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      margin-right: 14px;
      width: 100%;
      height: 38px;
      border-radius: 19px;
      font-size: 14px;
      background: #E1E9FF;
      color: #4A79FF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }


    .active {
      margin-right: 0;
      width: 100%;
      height: 38px;
      border-radius: 19px;
      font-size: 14px;
      background: linear-gradient(222deg, #3A6CFF, #004FEB);
      color: #FFFFFF;
    }
  }

  .result-modal-nr {
    margin-top: -30px;

    .result-modal-nr-t {
      font-size: 18px;
      color: #666666;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .result-modal-nr-t-num {
        margin-right: 10px;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }

      .result-modal-nr-t-tag {
        margin-left: 10px;
        display: inline-block;
        width: 50px;
        height: 22px;
        border-radius: 2px;
        font-size: 12px;
      }
    }

    .result-modal-nr-c {
      margin-top: 20px;
      font-weight: bold;
    }

    .result-modal-nr-desc {
      margin-top: 8px;
      color: #666666;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #999999;
    z-index: 99;

    >p {
      margin-bottom: 20px;
    }
  }

  .blue {
    background: #4A79FF !important;
    color: #FFFFFF !important;
    ;
  }

  .green {
    background: #19be6b !important;
    color: #FFFFFF !important;
    ;
  }
}

.video-wrap {
  width: 100%;
  height: 180px;

  >video {
    width: 100%;
    height: 100%;
    background-color: black;
  }
}
</style>

<style>
.exam .el-dialog__body {
  padding: 20px 20px;
}
</style>
