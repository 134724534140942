<template>
<div class="stu-exam-finish">

  <div v-if="trainUser.status == 2">
    <div class="stu-exam-finish-info">
      <img class="stu-exam-finish-info-img" :src="userInfo.avatar" alt="">
      <p class="stu-exam-finish-info-name">{{ userInfo.realname || userInfo.nickname }}</p>
      <p class="stu-exam-finish-info-desc">感谢你的作答，以下为你的答题分数:</p>
      <p class="stu-exam-finish-info-score">{{ trainUser.score }}分</p>
    </div>
    <div class="stu-exam-finish-nums">
      <div class="stu-exam-finish-num">
        <p class="stu-exam-finish-num-val">{{ util.secondsToMS(trainUser.use_time) }}</p>
        <p>答题用时</p>
      </div>
      <div class="stu-exam-finish-num">
        <p class="stu-exam-finish-num-val">{{ trainUser.have_finished }}</p>
        <p>总答题数</p>
      </div>
      <div class="stu-exam-finish-num">
        <p class="stu-exam-finish-num-val">{{ trainUser.have_finished == 0 ? '0.00' : (trainUser.right_count / trainUser.have_finished * 100).toFixed(2) }}%</p>
        <p>正确率</p>
      </div>
    </div>
  </div>
  <div class="stu-exam-finish-info" v-if="trainUser.status > 2">
    <img class="stu-exam-finish-info-img" :src="userInfo.avatar" />
    <p class="stu-exam-finish-info-name">{{ userInfo.realname || userInfo.nickname }}</p>
    <p class="stu-exam-finish-info-desc">感谢你的作答，等待老师判卷中....</p>
    <div class="stu-exam-finish-nums" style="width: auto">
      <div class="stu-exam-finish-num">
        <p class="stu-exam-finish-num-val">{{ util.secondsToMS(trainUser.use_time) }}</p>
        <p>答题用时</p>
      </div>
      <div class="stu-exam-finish-num">
        <p class="stu-exam-finish-num-val">{{ trainUser.have_finished }}</p>
        <p>总答题数</p>
      </div>
    </div>
  </div>
  <div class="stu-exam-finish-btn">
    <el-button type="primary" @click="goExamRecord">查看考试记录</el-button>
  </div>

</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "stuExamFinish",
  data(){
    return{
      trainUser:{},
      util:util
    }
  },
  props:{
    trainId:{
      type:String,
      default:''
    },
    userInfo:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    isSelf:{
      type:Boolean,
      default:false
    }
  },
  created(){
    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        id:this.trainId
      };
      this.loading = true;
      this.api.dataset.trainDataDetail(params).then((res)=>{
        this.loading = false;

        this.trainUser = res.trainUser;
        this.categoryType = res.category.type;



      }).catch((err)=>{
        this.loading = false;
      })
    },
    goExamRecord(){
      if(this.isSelf){
        this.$router.push({
          path:'/trainingcamp/selfexamrecord'
        })
      }else{
        this.$router.push({
          path:'/trainingcamp/myexamRecord',
          query:{
            tab:'record',
            categoryType:this.categoryType
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
  .stu-exam-finish{
    margin: 20px;
    padding: 20px;
    background-color: #FFFFFF;
    font-size: 14px;
    height: calc(100vh - 100px);
    overflow-y: auto;

    .stu-exam-finish-info{
      margin: 100px auto 0 auto;
      background: #F6F7FA;
      border-radius: 6px;
      padding: 20px;
      text-align: center;
      width: 800px;
      .stu-exam-finish-info-img{
        margin-top: -70px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .stu-exam-finish-info-name{
        margin: 10px 0 30px 0;
        font-size: 20px;
        font-weight: bold;
      }
      .stu-exam-finish-info-score{
        margin-top: 10px;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .stu-exam-finish-nums{
      margin: 20px auto 0 auto;
      background: #F6F7FA;
      border-radius: 6px;
      padding: 20px;
      width: 800px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        flex: 1;
        text-align: center;
        .stu-exam-finish-num-val{
          margin-bottom: 4px;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .stu-exam-finish-btn{
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
</style>
